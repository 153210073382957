import { S3Object } from "../api/restModel";
import React, { useCallback, useState } from "react";

export type bigFileWithUploadProgress = {
  file: File;
  uploadProgress: number;
};

export interface ILoadingFiles {
  fileWithUploadProgress: bigFileWithUploadProgress[];
  loadingFiles: File[];
  deletingFiles: S3Object[];
  movingFiles: S3Object[];
  downloadingIndicator: boolean;
  updateFileWithUploadProgress(file: File, uploadProgress: number): void;
  addLoadingFile(file: File): void;
  removeLoadingFile(file: File): void;
  addDeletingFile(file: S3Object): void;
  removeDeletingFile(file: S3Object): void;
  addMovingFile(file: S3Object): void;
  removeMovingFile(file: S3Object): void;
  setDownloadingIndicator(value: boolean): void;
}

export const LoadingIndicatorContext = React.createContext({} as ILoadingFiles);

type Props = {
  children: React.ReactNode;
};

export const LoadingIndicatorProvider = ({ children }: Props) => {
  const defaultContext = {
    fileWithUploadProgress: [],
    loadingFiles: [],
    deletingFiles: [],
    movingFiles: [],
    downloadingIndicator: false,

    updateFileWithUploadProgress: useCallback(
      (file: File, uploadProgress: number) => updateFileWithUploadProgress(file, uploadProgress),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    addLoadingFile: useCallback(
      (file: File) => addLoadingFile(file),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    removeLoadingFile: useCallback(
      (file: File) => removeLoadingFile(file),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    addDeletingFile: useCallback(
      (file: S3Object) => addDeletingFiles(file),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    removeDeletingFile: useCallback(
      (file: S3Object) => removeDeletingFiles(file),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    addMovingFile: useCallback(
      (file: S3Object) => addMovingFiles(file),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    removeMovingFile: useCallback(
      (file: S3Object) => removeMovingFiles(file),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    setDownloadingIndicator: useCallback(
      (value: boolean) => setDownloadingIndicator(value),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
  } as ILoadingFiles;

  const [context, setContext] = useState(defaultContext);

  const getIndexOfFile = (fileAndUploadProgressArray: bigFileWithUploadProgress[], file: File) => {
    let index = 0;
    for (const fileWithProgress of fileAndUploadProgressArray) {
      if (fileWithProgress.file.name == file.name) {
        return index;
      } else {
        index++;
      }
    }
    return -1;
  };

  const updateFileWithUploadProgress = (file: File, uploadProgress: number) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);
    const index = getIndexOfFile(newState.fileWithUploadProgress, file);

    if (index > -1) {
      newState.fileWithUploadProgress.splice(index, 1); // 2nd parameter means remove one item only
      if (file.size >= uploadProgress) {
        newState.fileWithUploadProgress.splice(index, 0, { file, uploadProgress }); //2nd Parameter deletes 0 items and inserts updated Progress at old index
      }
    } else {
      newState.fileWithUploadProgress.push({ file, uploadProgress });
    }
    setContext(newState);
  };

  const addLoadingFile = (file: File) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);
    newState.loadingFiles.push(file);

    setContext(newState as ILoadingFiles);
  };

  const removeLoadingFile = (file: File) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);

    const index = newState.loadingFiles.indexOf(file);
    if (index > -1) {
      newState.loadingFiles.splice(index, 1); // 2nd parameter means remove one item only
    }
    setContext(newState);
  };

  const addDeletingFiles = (file: S3Object) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);
    newState.deletingFiles.push(file);

    setContext(newState as ILoadingFiles);
  };

  const removeDeletingFiles = (file: S3Object) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);

    const index = newState.deletingFiles.indexOf(file);
    if (index > -1) {
      newState.deletingFiles.splice(index, 1); // 2nd parameter means remove one item only
    }
    setContext(newState);
  };

  const addMovingFiles = (file: S3Object) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);
    newState.movingFiles.push(file);

    setContext(newState as ILoadingFiles);
  };

  const removeMovingFiles = (file: S3Object) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);

    const index = newState.movingFiles.indexOf(file);
    if (index > -1) {
      newState.movingFiles.splice(index, 1); // 2nd parameter means remove one item only
    }
    setContext(newState);
  };

  const setDownloadingIndicator = (value: boolean) => {
    const newState = {} as ILoadingFiles;
    Object.assign(newState, context);
    newState.downloadingIndicator = value;
    setContext(newState);
  };

  return <LoadingIndicatorContext.Provider value={context}>{children}</LoadingIndicatorContext.Provider>;
};
