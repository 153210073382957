import { CircularProgress, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import useLoadingFiles from "../../../contexts/useLoadingFiles";

const MovingFileList = (): JSX.Element => {
  const { movingFiles } = useLoadingFiles();

  return (
    <List sx={{ overflowY: "auto", height: "100%", flexGrow: "1" }}>
      {movingFiles.map((file) => {
        return (
          <ListItem disablePadding key={file.name}>
            <ListItemIcon>
              <CircularProgress size={30} />
            </ListItemIcon>
            <ListItemText primary={file.name} secondary={Number(file.size / 1024 / 1024).toFixed(2) + "mb"} />
          </ListItem>
        );
      })}
    </List>
  );
};
export default MovingFileList;
