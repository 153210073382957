import { UploadingFilesList } from "../files/util/UploadUtil";
import { useIntl } from "react-intl";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

interface UploadProgressDialogProps {
  loadingFiles: File[];
  openDialog: boolean;
  closeDialog(): void;
}

export default function UploadProgressDialog(props: UploadProgressDialogProps) {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Dialog open={props.openDialog} onClose={props.closeDialog} PaperProps={{ sx: { maxWidth: "50%" } }}>
        <DialogTitle>{formatMessage({ id: "uploadProgressDialog.title" })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {formatMessage({
              id:
                props.loadingFiles.length > 0
                  ? "uploadProgressDialog.inProgress.text"
                  : "uploadProgressDialog.finished.text",
            })}
          </DialogContentText>
          <UploadingFilesList />
        </DialogContent>
      </Dialog>
    </div>
  );
}
