import { Box, Paper, Snackbar, Typography } from "@mui/material";
import { DriveFileMove } from "@mui/icons-material";
import { S3Object } from "../../api/restModel";
import { UploadingFilesList } from "./util/UploadUtil";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FileTable from "./FileTable";
import MovingFileList from "./util/MovingFileList";
import useLoadingFiles from "../../contexts/useLoadingFiles";

interface BucketViewProps {
  currentFolder: string;
  setCurrentFolder(currentFolder: string): void;
  folderMap: Map<string, S3Object[]>;
  updateFolderMap(key: string, value: S3Object[]): void;
  setFolderMap(map: Map<string, S3Object[]>): void;
}

export default function BucketView(props: BucketViewProps) {
  const { loadingFiles, movingFiles } = useLoadingFiles();
  const bread = props.currentFolder.substring(0, props.currentFolder.length - 1).split("/");
  const { formatMessage } = useIntl();

  useEffect(() => {
    // check if currentFolder still exist
    const folder = props.folderMap.get(props.currentFolder);

    if (!folder) {
      const currentFolder = props.currentFolder.substring(0, props.currentFolder.length - 1);
      const indexOfLastSlash = currentFolder.lastIndexOf("/");
      let parentFolder = "";
      let parentFolderKey = "";

      if (indexOfLastSlash === -1) {
        // parent is root folder
        parentFolder = "";
        parentFolderKey = "/";
      } else {
        // adding the removed slash
        parentFolder = props.currentFolder.substring(0, indexOfLastSlash) + "/";
        parentFolderKey = parentFolder;
      }

      props.setCurrentFolder(parentFolderKey);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.folderMap]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flex: "3 1 auto",
          flexDirection: "column",
          alignSelf: "auto",
          margin: "0.5rem 0.5rem 0.5rem 0",
        }}>
        <Paper
          elevation={2}
          sx={{
            height: "100%",
            display: "flex",
            width: "100%",
            flex: "5 1 180%",
            flexDirection: "column",
            alignSelf: "auto",
            overflow: "hidden",
          }}>
          <FileTable
            currentFolder={props.currentFolder}
            currentFolderName={bread[bread.length - 1] + "/"}
            folderMap={props.folderMap}
            updateFolderMap={props.updateFolderMap}
            setCurrentFolder={props.setCurrentFolder}
            setFolderMap={props.setFolderMap}
          />
        </Paper>

        <Snackbar
          open={loadingFiles.length > 0 || movingFiles.length > 0}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Paper elevation={2} sx={{ margin: 2, padding: 2, border: 1, borderColor: "grey.500" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                flexDirection: "column",
              }}>
              <Typography
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexGrow: "0",
                  flexShrink: "0",
                  overflowY: "auto",
                }}
                variant="h6"
                id="tableTitle"
                component="div">
                {loadingFiles.length > 0 && (
                  <>
                    <DriveFolderUploadIcon htmlColor="#fdb900" sx={{ mr: "0.75rem" }} />
                    {formatMessage({ id: "uploadProgress.header" })}
                  </>
                )}
                {movingFiles.length > 0 && (
                  <>
                    <DriveFileMove htmlColor="#fdb900" sx={{ mr: "0.75rem" }} />
                    {formatMessage({ id: "movingProgress.header" })}
                  </>
                )}
              </Typography>
              {loadingFiles.length > 0 && <UploadingFilesList />}
              {movingFiles.length > 0 && <MovingFileList />}
            </Box>
          </Paper>
        </Snackbar>
      </Box>
    </>
  );
}
