import { Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import useAPIError from "./useAPIError";

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  button: {
    borderColor: theme.palette.primary.main,
    margin: theme.spacing(0),
    color: theme.palette.grey[500],
    padding: theme.spacing(0),
  },
}));

export default function ErrorDialog() {
  const classes = useStyles();
  const error = useAPIError();
  const { formatMessage } = useIntl();

  const handleOk = () => {
    error.removeError();
  };
  const transformErrorMessage = (message: string | string[]) => {
    if (Array.isArray(message)) {
      if (message.length > 1 && message[0] != formatMessage({ id: "error.multi.error.message" })) {
        message.unshift(formatMessage({ id: "error.multi.error.message" }));
      }
      return message.map((message) => {
        return (
          <Typography gutterBottom key={message}>
            {message}
          </Typography>
        );
      });
    } else {
      return message;
    }
  };

  return error.message ? (
    <div>
      <Dialog open={error.showDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {error.type == "error"
            ? formatMessage({ id: "error.dialog.title" })
            : formatMessage({ id: "warning.dialog.title" })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          {transformErrorMessage(error.message)}
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
}
