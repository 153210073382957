import { Box } from "@mui/material";
import { S3Object } from "../../api/restModel";
import { isDescribeFolderAllowed } from "../../api/authorizationSettings";
import CreateFolderButton from "./buttons/CreateFolderButton";
import DeleteFolderButton from "./buttons/DeleteFolderButton";
import FolderTree from "./FolderTree";
import React from "react";

interface FolderTreeViewProps {
  currentFolder: string;
  setCurrentFolder(currentFolder: string): void;
  folderMap: Map<string, S3Object[]>;
  updateFolderMap(key: string, value: S3Object[]): void;
  setFolderMap(map: Map<string, S3Object[]>): void;
}

export default function FolderTreeView(props: FolderTreeViewProps) {
  const addNewFolder = (key: string, newFolder: S3Object) => {
    const currentFolderStructure = props.folderMap.get(key);

    if (isDescribeFolderAllowed(newFolder) && currentFolderStructure) {
      currentFolderStructure.push(newFolder);
      props.updateFolderMap(key, currentFolderStructure);
    }
  };

  const removeFolderFromTreeMap = (key: string) => {
    // getting parentFolder
    const keyWithoutLastSlash = key.substring(0, key.length - 1);
    const indexOfLastSlash = keyWithoutLastSlash.lastIndexOf("/");
    let parentFolder = "";
    let parentFolderKey = "";

    if (indexOfLastSlash === -1) {
      // parent is root folder
      parentFolder = "";
      parentFolderKey = "/";
    } else {
      // adding the removed slash
      parentFolder = keyWithoutLastSlash.substring(0, indexOfLastSlash) + "/";
      parentFolderKey = parentFolder;
    }

    // delete child node in folder structure
    const currentFolderStructure = props.folderMap.get(parentFolderKey);
    if (currentFolderStructure) {
      const s3Objects = currentFolderStructure.filter((s3Object) => s3Object.s3Key === key);
      const index = currentFolderStructure.indexOf(s3Objects[0]);
      if (index > -1) {
        currentFolderStructure.splice(index, 1);
      }
      props.updateFolderMap(key, currentFolderStructure);
    }

    const currentFolderMap = props.folderMap;
    currentFolderMap.delete(key);
    props.setFolderMap(currentFolderMap);
    props.setCurrentFolder(parentFolderKey);
  };

  return (
    <>
      <Box>
        <CreateFolderButton
          currentFolder={props.currentFolder}
          existingFoldersInCurrentFolderPath={props.folderMap.get(props.currentFolder)}
          addNewFolder={addNewFolder}
        />
        <DeleteFolderButton currentFolder={props.currentFolder} removeFolderFromTreeMap={removeFolderFromTreeMap} />
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          alignSelf: "auto",
          height: {
            xs: "calc(100% - 5.5rem)",
            sm: "calc(100% - 5.5rem)",
            md: "calc(100% - 5.5rem)",
            lg: "calc(100% - 2.5rem)",
            xl: "calc(100% - 2.5rem)",
          },
          overflow: "auto",
        }}>
        <FolderTree
          currentFolder={props.currentFolder}
          setCurrentFolder={props.setCurrentFolder}
          s3Key="/"
          folderName="/"
          folderMap={props.folderMap}
          updateFolderMap={props.updateFolderMap}
        />
      </Box>
    </>
  );
}
