import { Checkbox, createTheme } from "@mui/material";
import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

interface ConfirmDialogProps {
  openDialog: boolean;
  closeDialog(): void;
  onAgree(): void;
  titleMessage: { [key: string]: string };
  textMessage: { [key: string]: string };
  textMessageProps?: { [key: string]: string };
  handleConfirmDialogCheckboxChange?(): void;
}

function shortenTooLongMessages(textMessage: { [key: string]: string }) {
  if (textMessage) {
    let message = Object.values(textMessage)[0];
    if (message.length > 50) {
      message = message.replace(message.substring(15, message.length - 15), "[...]");
      return { [Object.keys(textMessage)[0]]: message };
    }
  }

  return textMessage;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const { formatMessage } = useIntl();
  const textMessage = props.textMessageProps ? shortenTooLongMessages(props.textMessageProps) : { fileName: "" };

  const theme = createTheme();
  return (
    <div>
      <Dialog
        open={props.openDialog}
        onClose={props.closeDialog}
        data-testid="confirmDialog"
        PaperProps={{ sx: { maxWidth: "50%" } }}>
        <DialogTitle>{formatMessage(props.titleMessage)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{formatMessage(props.textMessage, textMessage)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {typeof props.handleConfirmDialogCheckboxChange !== "undefined" ? (
            <>
              <Checkbox id="ConfirmDialogCheckBox" onChange={props.handleConfirmDialogCheckboxChange} />
              <label htmlFor="ConfirmDialogCheckBox" style={{ color: theme.palette.grey["400"] }}>
                {formatMessage({ id: "confirmDialog.checkbox.label" })}
              </label>
            </>
          ) : null}
          <Button onClick={() => props.onAgree()} autoFocus>
            {props.textMessage.id === "confirmDialog.existingFileName.text"
              ? formatMessage({ id: "confirmDialog.duplicate.file.confirm" })
              : formatMessage({ id: "confirmDialog.confirm" })}
          </Button>
          <Button onClick={props.closeDialog}>
            {props.textMessage.id === "confirmDialog.existingFileName.text"
              ? formatMessage({ id: "confirmDialog.duplicate.file.abort" })
              : formatMessage({ id: "confirmDialog.abort" })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
