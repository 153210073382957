import { S3Object } from "../api/restModel";
import React, { useCallback, useState } from "react";

export interface ICurrentFolder {
  currentFolderS3ObjectMap: Map<string, S3Object>;
  getCurrentFolderS3Object(currentFolder: string): S3Object;
  setCurrentFolderS3Object(folder: string, s3Content: S3Object): void;
}

export const CurrentFolderContext = React.createContext({} as ICurrentFolder);

type Props = {
  children: React.ReactNode;
};

export const CurrentFolderProvider = ({ children }: Props) => {
  const defaultContext = {
    currentFolderS3ObjectMap: new Map() as Map<string, S3Object>,
    getCurrentFolderS3Object: useCallback(
      (currentFolder: string) => getCurrentFolderS3Object(currentFolder),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    setCurrentFolderS3Object: useCallback(
      (folder: string, s3Content: S3Object) => setCurrentFolderS3Object(folder, s3Content),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
  } as ICurrentFolder;

  const [currentFolderContext, setCurrentFolderContext] = useState(defaultContext);

  const setCurrentFolderS3Object = (folder: string, s3Content: S3Object) => {
    const newState = {} as ICurrentFolder;
    Object.assign(newState, currentFolderContext);
    newState.currentFolderS3ObjectMap.set(folder, s3Content);

    setCurrentFolderContext(newState as ICurrentFolder);
  };

  const getCurrentFolderS3Object = (currentFolder: string) => {
    return currentFolderContext.currentFolderS3ObjectMap.get(currentFolder === "/" ? "" : currentFolder);
  };

  return <CurrentFolderContext.Provider value={currentFolderContext}>{children}</CurrentFolderContext.Provider>;
};
