import { CircularProgress, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { IApiUploadUrlResponse } from "../../../api/restModel";
import { bigFileWithUploadProgress } from "../../../contexts/LoadingIndicatorProvider";
import { formatErrorToMessageId } from "../../../api/errorUtil";
import { getUploadFileUrl } from "../../../api/restFacade";

import React from "react";
import useLoadingFiles from "../../../contexts/useLoadingFiles";

export type fileAndResponse = {
  file: File;
  response: IApiUploadUrlResponse;
};

const getProgressByFilename = (file: File, fileWithUploadProgress: bigFileWithUploadProgress[]) => {
  for (const fileWithProgress of fileWithUploadProgress) {
    if (fileWithProgress.file.name == file.name) {
      return fileWithProgress.uploadProgress;
    }
  }

  return null;
};

export function UploadingFilesList() {
  const { loadingFiles, fileWithUploadProgress } = useLoadingFiles();

  return (
    <List sx={{ overflowY: "auto", height: "100%", flexGrow: "1" }}>
      {loadingFiles.map((file) => {
        const progress = getProgressByFilename(file, fileWithUploadProgress);
        if (!progress) {
          return (
            <ListItem disablePadding key={file.name}>
              <ListItemIcon>
                <CircularProgress size={30} />
              </ListItemIcon>
              <ListItemText primary={file.name} secondary={Number(file.size / 1024 / 1024).toFixed(2) + "mb"} />
            </ListItem>
          );
        } else {
          return (
            <ListItem disablePadding key={file.name} sx={{ justifyContent: "right" }}>
              <ListItemIcon sx={{ alignItems: "center" }}>
                <CircularProgress variant="determinate" value={Math.round((progress / file.size) * 100)} size={30} />
              </ListItemIcon>
              <ListItemText primary={file.name} secondary={Number(file.size / 1024 / 1024).toFixed(2) + "mb"} />
            </ListItem>
          );
        }
      })}
    </List>
  );
}

export default function handleUpload(
  files: File[],
  filesAndResponses: Array<fileAndResponse>,
  currentFolder: string,
  formatMessage: any,
  addError: any,
  auth: any,
  getCurrentFolderS3Object: any,
  recursiveFileUpload: any
) {
  const fileToUploadCount = files.length;
  filesAndResponses.splice(0); //deletes all values in array
  for (const file of files) {
    if (file) {
      // check if fileName contains only allowed characters
      // https://docs.aws.amazon.com/de_de/AmazonS3/latest/userguide/bucketnamingrules.html?icmpid=docs_amazons3_console
      if (!file.name.match(/^[a-zA-Z0-9!_.*'()&$@=;+:,? -]*$/g)) {
        addError(formatMessage({ id: "formDialog.helpText.forbiddenCharacter" }, { fileName: file.name }), "error");
        return;
      }

      if (file.size > 5368709120) {
        // if size is larger than 5GB
        addError(formatMessage({ id: "error.sizeTooLarge.message" }, { fileName: file.name }), "error");
      } else {
        // show warning if file is larger than 50mb
        if (file.size > 52430000) {
          addError(formatMessage({ id: "warning.sizeIsLarge.message" }, { fileName: file.name }), "warning");
        }
        getUploadUrlAndCheckFileAlreadyExists(
          file,
          fileToUploadCount,
          filesAndResponses,
          currentFolder,
          recursiveFileUpload,
          formatMessage,
          addError,
          auth,
          getCurrentFolderS3Object
        );
      }
    }
  }
}

function getUploadUrlAndCheckFileAlreadyExists(
  file: File,
  fileToUploadCount: number,
  filesAndResponses: Array<fileAndResponse>,
  currentFolder: string,
  recursiveFileUpload: any,
  formatMessage: any,
  addError: any,
  auth: any,
  getCurrentFolderS3Object: any
) {
  getUploadFileUrl(auth, file, currentFolder === "/" ? "" : currentFolder, getCurrentFolderS3Object(currentFolder))
    .then((data) => {
      filesAndResponses.push({
        file: file,
        response: data,
      });
      if (filesAndResponses?.length === fileToUploadCount) {
        if (filesAndResponses != undefined) {
          recursiveFileUpload();
        }
      }
    })
    .catch((err) => {
      addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
    });
}
