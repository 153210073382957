import { Permission, S3Object } from "./restModel";

export function isDescribeFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DESCRIBE_FOLDER);
}

export function isCreateNewFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.CREATE_FOLDER);
}

export function isDeleteFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DELETE_FOLDER);
}

export function isRenameFolderAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.RENAME_FOLDER);
}

export function isDescribeFileAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DESCRIBE_FILE);
}

export function isDownloadFileAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DOWNLOAD_FILE);
}

export function isUploadFileAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.UPLOAD_FILE);
}

export function isDeleteFileAllowed(currentObject: S3Object): boolean {
  return isNeededRightInAuth(currentObject, Permission.DELETE_FILE);
}

function isNeededRightInAuth(currentObject: S3Object, neededRight: Permission): boolean {
  if (currentObject && neededRight) {
    return currentObject.permissions.includes(neededRight);
  }
  return false;
}
