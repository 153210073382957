import { Box, Button, Input } from "@mui/material";
import { S3Object } from "../../../api/restModel";
import { isUploadFileAllowed } from "../../../api/authorizationSettings";
import { useIntl } from "react-intl";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import useCurrentFolder from "../../../contexts/useCurrentFolder";

interface UploadButtonProps {
  currentFolder: string;
  rows: S3Object[];
  handleUpload(files: any): void;
}

export default function UploadButton(props: UploadButtonProps) {
  const { getCurrentFolderS3Object } = useCurrentFolder();
  const { formatMessage } = useIntl();
  const uploadDisabled = !isUploadFileAllowed(getCurrentFolderS3Object(props.currentFolder));

  return (
    <Box>
      <label htmlFor="contained-button-file">
        <Input
          sx={{ display: "none" }}
          id="contained-button-file"
          data-testid="upload-file-input"
          type="file"
          onChange={props.handleUpload}
          disabled={uploadDisabled}
          inputProps={{
            multiple: true, // <!-- allow multiple file selection
          }}
        />
        <Button
          sx={{ mr: "0.5rem", ml: "0.5rem" }}
          variant="outlined"
          startIcon={<UploadFileIcon />}
          component="span"
          disabled={uploadDisabled}>
          {formatMessage({ id: "button.uploadFile" })}
        </Button>
      </label>
    </Box>
  );
}
