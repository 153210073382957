import { CircularProgress, createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { zebStyles as parentZebStyles } from "../../zebstyles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UploadProgressDialog from "./UploadProgressDialog";
import UserProfile from "./UserProfile";
import ZebLogoIcon from "./ZebLogoIcon";
import useLoadingFiles from "../../contexts/useLoadingFiles";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  ...parentZebStyles(theme),
  logo: {
    marginRight: theme.spacing(2),
  },
}));

export default function Header() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { loadingFiles, deletingFiles, movingFiles, downloadingIndicator } = useLoadingFiles();
  const classes = useStyles();

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        flex: "1 1 100%",
        alignSelf: "auto",
      }}>
      <AppBar position="static">
        <Toolbar>
          <ZebLogoIcon size={2} className={classes.logo} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            S3 Webclient
          </Typography>
          {loadingFiles.length > 0 && (
            <Box sx={{ cursor: "pointer", mr: "2rem" }}>
              <div onClick={() => setOpenDialog(true)}>
                <CircularProgress data-testid="upload-progress" sx={{ color: "white" }} />
              </div>
            </Box>
          )}
          {(deletingFiles.length > 0 || movingFiles.length > 0 || downloadingIndicator) && (
            <Box sx={{ cursor: "pointer", mr: "2rem" }}>
              <div>
                <CircularProgress data-testid="deletion-progress" sx={{ color: "white" }} />
              </div>
            </Box>
          )}
          <UploadProgressDialog openDialog={openDialog} closeDialog={closeDialog} loadingFiles={loadingFiles} />
          <UserProfile />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
