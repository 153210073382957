import { useIntl } from "react-intl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";

interface FormDialogProps {
  openDialog: boolean;
  closeDialog(): void;
  validateValue(textFieldContent: string): string;
  onAgree(textFieldContent: string): void;
  titleMessage: { [key: string]: string };
  textMessage: { [key: string]: string };
  textMessageProps: { [key: string]: string };
}

export default function FormDialog(props: FormDialogProps) {
  const [textFieldContent, setTextFieldContent] = React.useState<string>("");
  const [helperText, setHelperText] = React.useState<string>("");

  const { formatMessage } = useIntl();

  useEffect(() => {
    setHelperText(props.validateValue(textFieldContent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFieldContent]);

  const closeDialog = () => {
    setHelperText("");
    setTextFieldContent("");
    props.closeDialog();
  };

  return (
    <Dialog open={props.openDialog} onClose={closeDialog} PaperProps={{ sx: { width: "600px" } }}>
      <form>
        <DialogTitle>{formatMessage(props.titleMessage)}</DialogTitle>
        <DialogContent sx={{ height: "8rem", pb: 0 }}>
          <DialogContentText>{formatMessage(props.textMessage, props.textMessageProps)}</DialogContentText>
          <TextField
            autoFocus
            aria-label="form-dialog-input"
            margin="dense"
            id="name"
            label=""
            fullWidth
            onChange={(event) => setTextFieldContent(event.target.value)}
            error={textFieldContent.length > 0 && props.validateValue(textFieldContent) !== ""}
            helperText={helperText && formatMessage({ id: helperText })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            disabled={textFieldContent === "" || props.validateValue(textFieldContent) !== ""}
            onClick={(event) => {
              event.preventDefault();
              props.onAgree(textFieldContent);
              setTextFieldContent("");
            }}>
            {formatMessage({ id: "formDialog.confirm" })}
          </Button>
          <Button onClick={closeDialog}>{formatMessage({ id: "formDialog.abort" })}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
