import { ILoadingFiles, LoadingIndicatorContext } from "./LoadingIndicatorProvider";
import { useContext } from "react";

function useLoadingFiles(): ILoadingFiles {
  const {
    fileWithUploadProgress,
    loadingFiles,
    deletingFiles,
    movingFiles,
    downloadingIndicator,
    updateFileWithUploadProgress,
    addLoadingFile,
    removeLoadingFile,
    addDeletingFile,
    removeDeletingFile,
    addMovingFile,
    removeMovingFile,
    setDownloadingIndicator,
  } = useContext(LoadingIndicatorContext);
  return {
    fileWithUploadProgress,
    loadingFiles,
    deletingFiles,
    movingFiles,
    downloadingIndicator,
    updateFileWithUploadProgress,
    addLoadingFile,
    removeLoadingFile,
    addDeletingFile,
    removeDeletingFile,
    addMovingFile,
    removeMovingFile,
    setDownloadingIndicator,
  };
}

export default useLoadingFiles;
