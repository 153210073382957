import { Box, Button } from "@mui/material";
import { S3Object } from "../../../api/restModel";
import { formatErrorToMessageId } from "../../../api/errorUtil";
import { getMultipleDownloadUrls } from "../../../api/restFacade";
import { isDownloadFileAllowed } from "../../../api/authorizationSettings";
import { useAuth } from "react-oidc-context";
import { useIntl } from "react-intl";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useAPIError from "../../../dialogs/error/useAPIError";
import useLoadingFiles from "../../../contexts/useLoadingFiles";

interface DownloadButtonProps {
  selectedRow: Set<S3Object> | undefined;
}

export default function DownloadButton(props: DownloadButtonProps) {
  const { formatMessage } = useIntl();
  const { addError } = useAPIError();
  const { setDownloadingIndicator } = useLoadingFiles();
  const auth = useAuth();

  const downloadFileCall = () => {
    setDownloadingIndicator(true);
    if (props.selectedRow) {
      getMultipleDownloadUrls(auth, props.selectedRow)
        .then((data) => {
          let i = 0;
          for (const d of data) {
            setTimeout(() => {
              const link = document.createElement("a");
              link.download = d[1];
              link.href = d[0];
              link.target = "_blank";
              link.click();
            }, i * 200);
            i += 1;
          }
          setDownloadingIndicator(false);
        })
        .catch((err) => {
          addError(formatMessage({ id: formatErrorToMessageId(err) }, { error: JSON.stringify(err) }), "error");
          setDownloadingIndicator(false);
        });
    }
  };

  const canFilesBeDownloaded = (): boolean => {
    if (props.selectedRow) {
      for (const file of props.selectedRow) {
        if (!isDownloadFileAllowed(file)) {
          return true;
        }
      }
    }
    return props.selectedRow?.size === 0;
  };

  return (
    <Box>
      <Button
        sx={{ mr: "0.5rem", ml: "0.5rem" }}
        startIcon={<FileDownloadIcon />}
        variant="outlined"
        component="span"
        disabled={canFilesBeDownloaded()}
        onClick={() => downloadFileCall()}>
        {formatMessage({ id: "button.downloadFile" })}
      </Button>
    </Box>
  );
}
