const POSSIBLE_ERROR_CODES = [
  "CONFIG_ENVIRONMENT_VARIABLE_NOT_SET",
  "CONFIG_NOT_FOUND",
  "CONFIG_SYNTAX_ERROR",
  "NOT_ALLOWED_TO_CREATE_FOLDER_WITH_NAME",
  "NOT_ALLOWED_TO_UPLOAD_FILE_WITH_NAME",
  "NOT_ALLOWED_TO_OPEN_FOLDER_WITH_NAME",
  "NON_UNIQUE_NAME",
  "NOT_ALLOWED_TO_DELETE_FILE",
  "NOT_ALLOWED_TO_RENAME_FILE",
  "NOT_ALLOWED_TO_DESCRIBE_FILE",
  "NOT_ALLOWED_TO_DOWNLOAD_FILE",
  "NOT_ALLOWED_TO_RENAME_FOLDER",
  "NOT_ALLOWED_TO_DELETE_CONTENT_WHILE_DELETING_FOLDER",
  "FILE_DOES_NOT_EXIST",
  "USER_HAS_NO_KNOWN_ROLE",
];

export function formatErrorToMessageId(err: any): string {
  if (err.code === "ECONNABORTED") {
    return "error.ECONNABORTED.message";
  } else if (
    err.response &&
    err.response.data &&
    err.response.data.errorCode &&
    POSSIBLE_ERROR_CODES.includes(err.response.data.errorCode)
  ) {
    return "error." + err.response.data.errorCode + ".message";
  } else if (
    err.response &&
    err.response.status &&
    (err.response.status == "404" ||
      err.response.status == "500" ||
      err.response.status == "401" ||
      err.response.status == "403")
  ) {
    return "error." + err.response.status + ".message";
  } else {
    return "error.unknown.message";
  }
}
