import { Theme } from "@mui/material/styles";

export const zebStyles = (theme: Theme) => {
  return {
    button: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      backgroundImage: `linear-gradient(to bottom,${theme.palette.primary.main} 0,${theme.palette.primary.dark} 100%)`,
      backgroundRepeat: "repeat-x",
      borderColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      boxShadow: "0 2px 3px rgb(3 3 3 / 10%)",
      padding: "6px 10px",
      fontSize: "14px",
      lineHeight: "1.3333333",
      borderRadius: "1px",
    },
    input: {
      lineHeight: "1.66666667",
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.common.white,
      backgroundImage: "none",
      borderRadius: "1px",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      transition: "border-olor ease-in-out .15s,box-shadow ease-in-out .15s",
      width: "100%",
      fontSize: "12px",
      fontFamily: "inherit",
      height: "40px",
    },

    logo: {
      color: theme.palette.primary.main,
    },
  };
};
