import { Permission, S3Object } from "../../api/restModel";
import { descendingNumberComparator, descendingStringComparator } from "../../utils/sortationUtils";

export interface HeadCell {
  id: keyof S3Object;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  numeric: boolean
): (
  a: { [key in Key]: number | string | Date | boolean | Permission[] | undefined },
  b: { [key in Key]: number | string | Date | boolean | Permission[] | undefined }
) => number {
  if (numeric) {
    return order === "desc"
      ? (a, b) => descendingNumberComparator(a, b, orderBy)
      : (a, b) => -descendingNumberComparator(a, b, orderBy);
  } else {
    return order === "desc"
      ? (a, b) => descendingStringComparator(a, b, orderBy)
      : (a, b) => -descendingStringComparator(a, b, orderBy);
  }
}
