export function descendingNumberComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function descendingStringComparator<T>(a: T, b: T, orderBy: keyof T) {
  return Intl.Collator().compare(String(a[orderBy]), String(b[orderBy]));
}
