import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { S3Object } from "../api/restModel";
import { useIntl } from "react-intl";
import FolderTreeView from "../components/folders/FolderTreeView";

interface MoveFileDialogProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  selectedFolder: string;
  setSelectedFolder: React.Dispatch<React.SetStateAction<string>>;
  folderMap: Map<string, S3Object[]>;
  setFolderMap(map: Map<string, S3Object[]>): void;
  updateFolderMap(key: string, value: S3Object[]): void;
  filesToMove: Set<S3Object> | undefined;
}
const MoveFileDialog = ({
  open,
  onClose,
  onConfirm,
  selectedFolder,
  setSelectedFolder,
  folderMap,
  setFolderMap,
  updateFolderMap,
  filesToMove,
}: MoveFileDialogProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const canFilesBeMoved = (): boolean => {
    if (!filesToMove) return false;
    const [item] = filesToMove;
    const sourceFolder = item.parentFolder ? item.parentFolder : "/";

    // check if: selected folder is source folder
    if (selectedFolder === sourceFolder) return false;

    // check if: sourcefolder has been deleted
    if (!folderMap.has(sourceFolder)) return false;

    return true;
  };

  return (
    <Dialog open={open} onClose={onClose} data-testid="moveFileDialog" maxWidth="lg">
      <DialogTitle>{formatMessage({ id: "moveFileDialog.title" })} </DialogTitle>
      <DialogContent>
        <FolderTreeView
          currentFolder={selectedFolder}
          setCurrentFolder={setSelectedFolder}
          folderMap={folderMap}
          updateFolderMap={updateFolderMap}
          setFolderMap={setFolderMap}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} autoFocus disabled={!canFilesBeMoved()}>
          {formatMessage({ id: "confirmDialog.confirm" })}
        </Button>
        <Button onClick={onClose}>{formatMessage({ id: "confirmDialog.abort" })}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveFileDialog;
